<template>
  <div>
    <b-modal
      id="modal-login"
      hide-footer
      centered
      no-close-on-backdrop
      title="Filters"
    >
      <b-form>
        <b-form-group label="Tag" invalid-feedback="tag is required.">
          <v-select
            v-model="filter.tagID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="tagData"
            :clearable="false"
            :reduce="(val) => val.id"
            label="name"
            ref="tag"
            placeholder="Select tag"
          />
        </b-form-group>
        <b-form-group label="Status" invalid-feedback="tag is required.">
          <v-select
            v-model="filter.statusID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusData"
            :clearable="false"
            :reduce="(val) => val.id"
            label="name"
            ref="status"
            placeholder="Select status"
          />
        </b-form-group>
        <b-form-group
          label="Reference"
          invalid-feedback="reference is required."
        >
          <v-select
            v-model="filter.referenceID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="referenceData"
            :clearable="false"
            :reduce="(val) => val.id"
            label="name"
            ref="refer"
            placeholder="Select reference"
          />
        </b-form-group>
        <b-form-group label="Priority" invalid-feedback="priority is required.">
          <v-select
            v-model="filter.priorityID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="priorityData"
            :clearable="false"
            :reduce="(val) => val.id"
            label="name"
            ref="prior"
            placeholder="Select priority"
          />
        </b-form-group>
        <b-form-group label="Category" invalid-feedback="category is required.">
          <v-select
            v-model="filter.categoryID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="categoryData"
            :clearable="false"
            :reduce="(val) => val.id"
            label="name"
            ref="cat"
            placeholder="Select category"
          />
        </b-form-group>
        <b-form-group label="Date" invalid-feedback="Date is required.">
          <flat-pickr
            ref="fil_date"
            v-model="filter.date"
            :config="config"
            class="form-control"
            placeholder="Select Date."
          />
        </b-form-group>
        <b-form-group
          label="Next Followup Date"
          invalid-feedback="Date is required."
        >
          <flat-pickr
            ref="fil_nextdate"
            v-model="filter.nextFollowUpDate"
            :config="config"
            class="form-control"
            placeholder="Select Date."
          />
        </b-form-group>
      </b-form>
      <b-row class="mt-2 mb-50">
        <b-col style="display: flex; justify-content: end">
          <b-button
            @click="clearFilter()"
            class="mr-1"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
          >
            <span class="align-middle">Clear</span>
          </b-button>
          <b-button
            @click="LoadData()"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
          >
            <span class="align-middle">Show data</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0 mr-1">
          {{ sidebarTitle }}
        </h5>
        <div class="d-flex align-items-center">
          <b-form-checkbox
            v-model="notification"
            v-b-tooltip.hover.right
            title="Notifications"
            switch
            @input="setNoti()"
          />
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hideSideBar()"
          />
        </div>
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col cols="12">
            <b-form-group label="Name *" invalid-feedback="Name is required.">
              <b-form-input
                id="mc-first-name"
                placeholder="Enter student name"
                ref="name"
                v-model="myObj.name"
                @focusout="CheckName()"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Father name "
              invalid-feedback="Father name is required."
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter student father name"
                ref="fname"
                v-model="myObj.father_name"
              />
              <!-- @focusout="CheckFName()" -->
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Class"
              invalid-feedback="Class is required."
              ref="class"
            >
              <v-select
                v-model="myObj.cls"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classes"
                :clearable="false"
                :reduce="(val) => val.id"
                label="name"
                ref="class"
                placeholder="Select class"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Contact number *"
              invalid-feedback="Please enter correct phone number."
            >
              <!-- <b-input-group ref="phoneNumber">
                  <b-input-group-prepend is-text>
                    PAK (+)
                  </b-input-group-prepend>
                  <the-mask
                    type="tel"
                    :mask="['## ### #######']"
                    id="phone"
                    v-model="myObj.contact"
                    class="form-control"
                    @click="CheckMobile()"
                    ref="contact"
                    placeholder="92 333 9876543"
                  ></the-mask>
                </b-input-group> -->
              <vue-tel-input v-model="myObj.contact"></vue-tel-input>
              <small class="mr-1 text-danger" v-if="contacterror"
                >Contact number is required.</small
              >
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Email"
              invalid-feedback="Email address is required."
            >
              <b-form-input
                ref="email"
                type="email"
                id="mc-email"
                placeholder="myskool@example.com"
                v-model="myObj.email"
              />
              <!-- @focusout="CheckEmail()" -->
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required."
              ref="date"
            >
              <flat-pickr
                ref="date"
                v-model="myObj.date"
                :config="config"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Purpose "
              invalid-feedback="Purpose is required."
            >
              <b-form-input
                ref="purpose"
                type="text"
                id="mc-email"
                placeholder="Enter purpose here."
                v-model="myObj.purpose"
              />
              <!-- @focusout="CheckPurpose()" -->
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Tag" invalid-feedback="Tag is required.">
              <b-form-input placeholder="Enter tag here." v-model="myObj.tag" />
              <!-- @focusout="CheckTag()" -->
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :state="nameState"
              label="Upload Documents"
              invalid-feedback="Documents are required"
              required
              ref="docs"
            >
              <b-button
                ref="docs"
                @click="$refs.psamplefile.click()"
                variant="primary"
                :disabled="request || mydocloading"
                block
              >
                <b-spinner
                  v-if="mydocloading"
                  type="grow"
                  small
                  label="Loading..."
                />
                <span v-else>Upload</span>
              </b-button>
              <input
                type="file"
                id="psamplefile"
                hidden
                ref="psamplefile"
                multiple
                @change="PsampleAdd()"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div>
              <b-badge
                style="margin-bottom: 5px"
                class="p-1"
                v-for="(file, index) in attachList"
                :key="index"
                variant="light-primary"
              >
                <a :href="file" target="_blank" class="text-primary">
                  {{ index + 1 + " : " + file.slice(0, 40) }}
                </a>

                <i
                  class="text-danger fa fa-trash"
                  style="cursor: pointer"
                  @click="deleteDocument(index)"
                ></i>
              </b-badge>
            </div>
          </b-col>

          <b-col class="mt-1" md="12">
            <!-- <b-button
    class="mr-1"
  @click="BacktoGrid()" 
    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
    variant="outline-secondary"
  >
    <feather-icon
      icon="ChevronLeftIcon"
      
    />
    <span class="align-middle"> Back</span>
   
  </b-button> -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1"
              @click="Add()"
              :disabled="request || mydocloading"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-if="request == false"> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
          <!-- <b-col cols="12">
      <b-form-group>
        <b-form-checkbox
          id="checkbox-10"
          name="checkbox-10"
          value="Remember_me"
        >
          Remember me
        </b-form-checkbox>
      </b-form-group>
    </b-col> -->

          <!-- submit and reset -->
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Follow Up</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row
          v-for="(item, ind) in followObj"
          :key="ind"
          style="display: flex; align-items: center"
        >
          <b-col md="3" class="pr-0">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required."
              ref="date"
            >
              <flat-pickr
                ref="date"
                :config="config"
                v-model="item.date"
                class="form-control"
                placeholder="Select Date."
              />
              <!-- @on-change="CheckDate()" -->
            </b-form-group>
          </b-col>
          <b-col md="4" class="pr-0">
            <b-form-group
              label="Response"
              invalid-feedback="Response is required."
              ref="response"
            >
              <b-form-input
                id="mc-first-name"
                ref="response"
                placeholder="Enter response here"
                v-model="item.response"
              />
            </b-form-group>
          </b-col>
          <b-col md="3" class="pr-0">
            <b-form-group
              label="Next Date"
              invalid-feedback="Next Date is required."
              ref="next_date"
            >
              <flat-pickr
                ref="next_date"
                :config="config"
                v-model="item.nextDate"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
          </b-col>
          <b-col md="1">
            <b-button
              v-if="ind == followObj.length - 1"
              @click="AddDesc()"
              variant="primary"
              class="btn-icon rounded-circle"
            >
              <feather-icon size="18" icon="PlusIcon" />
            </b-button>
            <b-button
              v-else
              @click="removeDesc(item, ind)"
              variant="outline-danger"
              class="btn-icon rounded-circle"
            >
              <feather-icon size="18" icon="Trash2Icon" />
            </b-button>
          </b-col>
        </b-row>
        <b-row class="">
          <b-col md="12" class="">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              @click="Save()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>
    <b-card>
      <b-row class="mt-0 align-items-baseline">
        <b-col xl="2" lg="2" md="4" sm="12" cols="12" v-if="rights.add">
          <b-button
            block
            @click="Edit2(0)"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Inquiry</span>
          </b-button>
        </b-col>
        <!-- <b-col
          xl="2"
          lg="2"
          md="4"
          sm="12"
          cols="12"
          class="mt-sm-50"
          v-if="rights.add"
        >
          <b-button
            block
            @click="Edit2(-1)"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon icon="UserPlusIcon" class="mr-50" />
            <span class="align-middle">Visitor</span>
          </b-button>
        </b-col> -->
        <b-col xl="2" lg="2" md="4" sm="12" cols="12" class="mt-sm-50">
          <b-button
            v-if="!byFilter"
            block
            @click="$bvModal.show('modal-login')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
          >
            <div>
              <feather-icon icon="FilterIcon" class="mr-50" />
              <span class="align-middle">Filter</span>
            </div>
          </b-button>
          <b-button
            v-else
            block
            @click="LoadData()"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
          >
            <feather-icon icon="XIcon" class="mr-50" />
            <span class="align-middle">Clear</span>
          </b-button>
        </b-col>

        <b-col
          class="mt-lg-0 mt-md-50 mt-sm-50 mt-1 d-flex"
          xl="8"
          lg="8"
          md="4"
          sm="12"
          cols="12"
        >
          <b-form-group class="w-100 mr-50" style="">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
          <b-button
            v-if="!rights.disableExport"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            v-b-tooltip.hover.top
            title="Export"
            class="btn-icon mb-1"
            :disabled="exporting"
            @click="exportData()"
          >
            <b-spinner v-if="exporting" small />
            <feather-icon v-else icon="UploadIcon" class="" />
          </b-button>
        </b-col>
        <!-- <b-col
          xl="2"
          lg="3"
          md="6"
          sm="12"
          cols="12"
          class="d-flex align-items-center justify-content-between pl-0 pl-sm-1"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            v-b-tooltip.hover.top
            title="Export"
            class="btn-icon mb-1"
            :disabled="exporting"
            @click="exportData()"
          >
            <b-spinner v-if="exporting" small />
            <feather-icon v-else icon="UploadIcon" class="" />
          </b-button>
        </b-col> -->
      </b-row>
      <b-col cols="12" class="d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="filters.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
      <b-table
        class="mt-1"
        :tbody-tr-class="rowClass"
        ref="selectableTable"
        :items="filters"
        :fields="fields"
        :busy="dataLoading"
        :current-page="currentPage"
        :per-page="perPage"
        show-empty
        responsive
        hover
        small
        :selectable="rights.edit"
        select-mode="single"
        @row-selected="Edit2($event[0])"
      >
        <template #empty="scope">
          <h3 class="mt-1 mb-1" style="text-align: center">No records found</h3>
        </template>
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #cell(index)="data">
          <!-- {{ data.index + 1 }} -->
          {{ filters.indexOf(data.item) + 1 }}
        </template>

        <template #cell(name)="data">
          <div style="">
            <b-badge variant="light-primary" style="margin-bottom: 4px">
              {{ data.item.inquiry.name }}
            </b-badge>
            <br />
            <b-badge variant="light-primary" style="margin-bottom: 4px">
              POC : {{ data.item.inquiry.father_name }}
            </b-badge>
            <br />
            <b-badge variant="light-primary" style="margin-bottom: 4px">
              {{ data.item.inquiry.contact }}
            </b-badge>
            <br />
            <b-badge
              variant="light-primary"
              style="margin-bottom: 4px"
              v-if="data.item.inquiry.email"
            >
              {{ data.item.inquiry.email }}
            </b-badge>
            <br />
            <span v-if="data.item.inquiry.note" class="font-weight-bold">
              {{ data.item.inquiry.note }}
            </span>
            <!-- <b-badge
              variant="light-primary"
              style="margin-bottom: 4px"
              v-if="data.item.inquiry.note"
            >
              {{ data.item.inquiry.note }}
            </b-badge> -->
          </div>
        </template>

        <template #cell(inquiry.nextFollowup)="data">
          <b-badge
            variant="light-primary"
            v-if="data.item.inquiry.nextFollowup"
          >
            {{
              new Date(data.value).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
        </template>
        <template #cell(inquiry.date)="data">
          <b-badge variant="light-primary">
            {{
              new Date(data.value).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
        </template>
        <template #head(actions)="data">
          <div class="text-center">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex">
            <b-button
              v-if="rights.edit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.left
              title="Edit"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Edit2(data.item)"
            >
              <!-- :to="{
                name: 'inquiry-form',
                params: {
                  id: data.item,
                },
              }" -->
              <feather-icon icon="EditIcon" class="" />
            </b-button>

            <b-button
              v-if="rights.edit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              v-b-tooltip.hover.left
              title="Move to Registration"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="move(data.item)"
            >
              <feather-icon icon="CheckCircleIcon" class="" />
            </b-button>

            <b-button
              v-if="rights.delete"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.right
              title="Delete"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Delete(data.item.inquiry.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>

            <!-- <b-button
              v-if="rights.edit"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              v-b-tooltip.hover.right
              title="Follow Up"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="openFollow(data.item.id)"
            >
              <feather-icon icon="CalendarIcon" />
            </b-button> -->
          </div>
        </template>
        <!-- <template #cell(status)="data">
      <b-badge :variant="status[1][data.value]">
        {{ status[0][data.value] }}
      </b-badge>
    </template> -->
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { TheMask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";

import * as XLSX from "xlsx";

export default {
  components: {
    //Multiselect,
    TheMask,
    BInputGroup,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    //BAvatar,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BSpinner,
    BFormCheckbox,
    BForm,
    BContainer,
    //BCardTitle,
    //BCardText,
    //BMediaAside,
    //BImg ,
    // BMedia,
    //BAvatar,
    BLink,
    // BSpinner,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    vSelect,
    flatPickr,
    //'gallery': VueGallery
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
          disableImport: ch.disableImport,
          disableExport: ch.disableExport,
        };
      });
    });
    // console.log(right);
    this.$store.commit("setRights", right);

    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadData();
      this.loadTags();
      // this.loadActivity();
      this.loadCategory();
      this.loadPriority();
      this.loadRefs();
      this.loadStatus();
    }

    // this.LoadData();
    // this.loadTags();
    // // this.loadActivity();
    // this.loadCategory();
    // this.loadPriority();
    // this.loadRefs();
    // this.loadStatus();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),

    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.inquiry.name
            .toLowerCase()
            .match(this.searchQuery.toLowerCase()) ||
          pro.inquiry.contact
            .toLowerCase()
            .match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      followObj: [
        {
          id: 0,
          inquiryID: 0,
          date: "",
          nextDate: "",
          response: "",
        },
      ],
      visibility2: false,
      currentID: null,
      rights: {},
      classes: [],
      filterTags: [],
      filterClasses: [],
      filter: {
        tagID: 0,
        categoryID: 0,
        referenceID: 0,
        priorityID: 0,
        statusID: 0,
        nextFollowUpDate: null,
        date: null,
        campusID: this.$store.state.userData.cId,
        db: this.$store.state.userData.db,
      },
      priorityData: [],
      statusData: [],
      activityData: [],
      referenceData: [],
      categoryData: [],
      tagData: [],
      byFilter: false,
      filterLoading: false,
      contacterror: false,
      mydocloading: false,
      docsloading: "Upload",
      request: false,
      colorDep: [],
      visibility1: false,
      color: [
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
      ],

      sidebarTitle: "Add Class",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,
      departments: [],
      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: null,

      errors: {
        status: false,
      },
      statusOptions: ["In stock", "Out stock"],
      filterData: "",
      date: {
        range: "",
      },
      index: null,
      cover: "",
      docs: [],
      partners: [],
      fields: [
        { label: "S.No", key: "index" },
        { label: "name", key: "name" },
        // { label: "tag", key: "inquiry.tag" },
        { label: "Date", key: "inquiry.date" },
        { label: "next Followup", key: "inquiry.nextFollowup" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      selected: null,
      typeOptions: [
        { text: "Inquiry", value: true },
        { text: "Visitors", value: false },
      ],
      currentType: true,
      rangeDate: null,
      myObj: {
        // id: 0,
        // name: "",
        // father_name: "",
        // contact: "",
        // email: "",
        // address: "",
        // purpose: "",
        // tag: "",
        // date: "0001-01-01T00:00:00",
        // attachments: "",
        // campusID: this.$store.state.userData.cId,
      },
      notification: this.$store.state.inqNotification,
      attachList: [],
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      detailObj: {},
      avatarURL:
        "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/female/110.png",
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },

      uploadFields: {
        Name: "name",
        Father: "father_name",
        Contact: "contact",
        Email: "email",
        Purpose: "purpose",
        Tag: "tag",
        Date: "date",
      },
      exporting: false,
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    started() {
      this.exporting = true;
      console.log("start");
    },
    ended() {
      this.exporting = false;
      console.log("end");
    },

    exportData() {
      this.exporting = true;

      const newData = this.items.map((item) => ({
        name: item.inquiry.name,
        father: item.inquiry.father_name,
        contact: item.inquiry.contact,
        email: item.inquiry.email,
        reference: item.inquiry.referenceID,
        purpose: item.inquiry.purpose,
        tag: item.inquiry.tag,
        date: item.inquiry.date
          ? item.inquiry.date.split("T")[0]
          : item.inquiry.date,
        nextFollowup: item.inquiry.nextFollowup
          ? item.inquiry.nextFollowup.split("T")[0]
          : item.inquiry.nextFollowup,
        // attachments: item.attachments,
      }));

      const worksheet = XLSX.utils.json_to_sheet(newData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
      XLSX.writeFile(workbook, "inquiry.xlsx");

      this.exporting = false;
    },

    openFollow(id) {
      this.currentID = id;
      this.followObj = [
        {
          id: 0,
          inquiryID: id,
          date: "",
          nextDate: "",
          response: "",
          campusID: this.$store.state.userData.cId,
        },
      ];
      console.log(this.followObj);
      this.visibility2 = true;
    },
    AddDesc() {
      this.followObj.push({
        id: 0,
        inquiryID: this.currentID,
        date: "",
        nextDate: "",
        response: "",
        campusID: this.$store.state.userData.cId,
      });
      // console.log(this.myObj);
    },
    async removeDesc(item, ind) {
      if (item.id == 0) {
        this.followObj.splice(ind, 1);
        // console.log(this.followObj);
      } else {
        // var status = await this.delete({
        //   url:
        //     this.$store.state.domain +
        //     " /" +
        //     item.id +
        //     "?db=" +
        //     this.$store.state.userData.db,
        //   body: null,
        //   grading: true,
        //   context: this,
        //   token: this.$store.state.userData.token,
        // });
        // if (status) this.followObj.splice(ind, 1);
        // console.log(this.followObj);
      }
    },
    CheckValues() {
      let state = true;
      this.followObj.forEach((el) => {
        if (el.date == "" || el.response.trim() == "" || el.nextDate == "") {
          state = false;
        }
      });
      return state;
    },
    async Save() {
      if (this.CheckValues() == true) {
        console.log("obj:", this.followObj);
        // this.request = true;

        // var status = await this.post({
        //   url:
        //     this.$store.state.domain +
        //     "  ?db=" +
        //     this.$store.state.userData.db,
        //   body: this.followObj,
        //   message: "expenses added successfully.",
        //   context: this,
        //   token: this.$store.state.userData.token,
        // });
        // this.request = false;
        // if (status) {
        //   this.LoadData();
        //   this.visibility2 = false;
        // }
      } else {
        this.$bvToast.toast("Please fill the follow up details correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
    setNoti() {
      this.$store.commit("setInquiry", this.notification);
    },
    deleteDocument(index) {
      this.attachList.splice(index, 1);
      // console.log("list", this.attachList);
      this.myObj.attachments = this.attachList.join(",");
    },
    PsampleAdd() {
      // console.log(this.$refs.psamplefile.files);

      if (this.$refs.psamplefile.files.length !== 0) {
        this.mydocloading = true;
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.psamplefile.files.length; i++) {
          formdata.append("file", this.$refs.psamplefile.files[i]);
        }

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch("https://upload.appick.io", requestOptions)
          .then((response) => response.json()) //2
          .then((result) => {
            //this.moreImages.push(result.myresp);

            for (var i = 0, len = result.myresp.length; i < len; ++i) {
              // this.sampleloading = false;
              var fn = result.myresp[i].path;
              // console.log("fn", fn);
              this.attachList.push(fn);
            }
            // console.log("Hello", this.attachList);
            this.myObj.attachments = this.attachList.join(",");
            this.mydocloading = false;
          })

          .catch((error) => console.log(error));
      }
    },
    EditDep(row) {
      // console.log("myRow",row);
      this.depObj.id = row.id;
      this.depObj.name = row.name;
      this.depObj.campusID = this.$store.state.userData.cId;
      this.visibility1 = true;
      this.sidebarTitle = "Edit Department";
      this.sidebarButton = "Update";
      var elem = this.$refs["depname"];
      elem.state = undefined;
    },
    OpenDepartmentF() {
      this.visibility1 = true;
      this.depObj.id = 0;
      this.depObj.name = "";
      this.depObj.campusID = this.$store.state.userData.cId;
      this.sidebarTitle = "Add Department";
      this.sidebarButton = "Save";
      var elem = this.$refs["depname"];
      elem.state = undefined;
    },
    move(obj) {
      this.$router.push({
        name: "student-registration",
        params: {
          obj,
        },
      });
    },
    Edit2(id) {
      this.$router.push({
        name: "inquiry-form",
        params: {
          id,
        },
      });
    },
    async Edit(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "StudentInquiries/GetSelected?id=" +
          id +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      this.myObj = await this.get(obj);
      // console.log("editObj:", this.myObj);
      if (this.myObj.attachments !== null && this.myObj.attachments !== "") {
        this.attachList = this.myObj.attachments.split(",");
        // console.log(this.attachList);
      } else this.attachList = [];
      // if (this.myObj.attachments !== "") {
      //   this.attachList = this.myObj.attachments.split(",");
      //   // this.attachList = this.attachList.pop();
      //   console.log("att", this.attachList.length);
      //   this.myObj.attachments = "";
      // } else {
      //   this.attachList = [];
      // }
      this.sidebarTitle = "Edit Inquiry";
      this.sidebarButton = "Update";
      this.visibility = true;

      var elem = this.$refs["name"];
      elem.state = undefined;
      var elem = this.$refs["fname"];
      elem.state = undefined;
      this.contacterror = false;
      var elem = this.$refs["email"];
      elem.state = undefined;
      var elem = this.$refs["purpose"];
      elem.state = undefined;
      var elem = this.$refs["tag"];
      elem.state = undefined;
    },
    AddOpen() {
      this.attachList = [];
      this.myObj = {
        id: 0,
        name: "",
        father_name: "",
        contact: "",
        email: "",
        address: "",
        purpose: "",
        tag: "",
        date: new Date(),
        attachments: "",
        campusID: this.$store.state.userData.cId,
        cls: 0,
      };
      this.sidebarTitle = "Add Inquiry";
      this.sidebarButton = "Save";
      this.visibility = true;

      var elem = this.$refs["name"];
      elem.state = undefined;
      var elem = this.$refs["fname"];
      elem.state = undefined;
      this.contacterror = false;
      var elem = this.$refs["email"];
      elem.state = undefined;
      var elem = this.$refs["purpose"];
      elem.state = undefined;
      var elem = this.$refs["tag"];
      elem.state = undefined;
    },
    CheckMobile() {
      // var re = /^(\+92|0|92)[0-9]{10}$/;
      // if (re.test(this.myObj.contact) && this.myObj.contact !== "") {
      //   return (this.contacterror = false);
      // } else {
      //   return (this.contacterror = true);
      // }
      // console.log(this.myObj.contact.length);
      if (this.myObj.contact.length == 12) {
        return (this.contacterror = false);
      } else {
        return (this.contacterror = true);
      }
    },
    CheckEmail() {
      var elem = this.$refs["email"];
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (
        re.test(String(this.myObj.email).toLowerCase()) &&
        this.myObj.email !== ""
      ) {
        return (elem.state = true);
      } else {
        return (elem.state = false);
      }
    },
    CheckFName() {
      var elem = this.$refs["fname"];
      if (this.myObj.father_name == "" || this.myObj.father_name > 20) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name == "" || this.myObj.name > 20) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckPurpose() {
      var elem = this.$refs["purpose"];
      if (this.myObj.purpose == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTag() {
      var elem = this.$refs["tag"];
      if (this.myObj.tag == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    hideSideBar() {
      this.visibility = false;
      //  this.$refs.mysidebar.hide();
      //this.$bvModal.hide("modal-login");
    },
    hideDepSideBar() {
      this.visibility1 = false;
      //  this.$refs.mysidebar.hide();
      //this.$bvModal.hide("modal-login");
    },
    Form() {
      this.$router.push("/apps/schoolinfo");
    },
    checkTitle() {
      var elem = this.$refs["title"];
      return (elem.state = this.myObj.name.length > 2 ? true : false);
    },

    checkStatus() {
      if (this.myObj.Status == "") {
        return (this.errors.status = true);
      } else {
        return (this.errors.status = false);
      }
    },
    OpenAddModal() {
      this.$bvModal.show("modal-login");
      this.myObj.flavourId = 0;
      this.myObj.vendorId = this.$store.state.userData.userID;
      this.myObj.Name = "";
      this.myObj.Status = "";
    },
    OpenEditModal(row) {
      this.$bvModal.show("modal-login");
      this.myObj.flavourId = row.flavourId;
      this.myObj.vendorId = row.vendorId;
      this.myObj.Name = row.Name;
      this.myObj.Status = row.Status;
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "studentinquiries/Remove?db=" +
            this.$store.state.userData.db +
            "&id=" +
            id,
          message: "Inquiry deleted successfully.",
          context: this,
          body: null,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },

    async Add() {
      // console.log(this.myObj);
      this.CheckName();
      this.CheckMobile();
      // this.CheckFName();
      // this.CheckEmail();
      // this.CheckPurpose();
      // this.CheckTag();

      if (
        this.CheckName() == false ||
        this.CheckMobile() == true
        // this.CheckFName() == false ||
        // this.CheckEmail() == false ||
        // this.CheckPurpose() == false ||
        // this.CheckTag() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        // this.attachList.forEach((el) => {
        //   this.myObj.attachments += el + ",";
        // });
        this.myObj.notification = this.notification;
        if (this.myObj.id == 0) {
          this.request = true;
          if (this.myObj.date == "") this.myObj.date = new Date();
          // const d = new Date();
          // this.myObj.date = d;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "studentinquiries/Add?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId +
              "&sms=" +
              this.notification,
            body: this.myObj,
            message: "Inquiry added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          //this.visibility1 = false;
          if (status) {
            this.LoadData();
            this.visibility = false;
          }
        } else {
          //Edit
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "studentinquiries/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Inquiry updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.LoadData();
            this.visibility = false;
          }
        }
      }
    },

    clearFilter() {
      this.filter = {
        tagID: 0,
        categoryID: 0,
        referenceID: 0,
        priorityID: 0,
        statusID: 0,
        nextFollowUpDate: null,
        date: null,
        campusID: this.$store.state.userData.cId,
        db: this.$store.state.userData.db,
      };
    },
    async LoadData() {
      // let page = this.currentPage;
      this.byFilter = false;
      this.dataLoading = true;
      this.$bvModal.hide("modal-login");
      this.items = [];

      if (this.filter.nextFollowUpDate == "")
        this.filter.nextFollowUpDate = null;
      if (this.filter.date == "") this.filter.date = null;

      let myitem = await this.post({
        url: this.$store.state.domain + "studentinquiries/FilterLeads",
        token: this.$store.state.userData.token,
        body: this.filter,
        subjects: true,
        context: this,
      });
      // console.log(myitem);
      this.items = myitem;
      this.$store.commit("setInquiryData", this.items);

      this.dataLoading = false;
      // setTimeout(() => {
      //   this.currentPage = page;
      // }, 100);
    },
    async LoadFilter() {
      this.byFilter = true;
      this.dataLoading = true;
      var status = await this.post({
        url: this.$store.state.domain + "studentinquiries/Filter",
        body: this.filter,
        subjects: true,
        context: this,
        token: this.$store.state.userData.token,
      });
      // this.request = false;
      this.$bvModal.hide("modal-login");
      // console.log(status);
      this.items = status;
      this.$store.commit("setInquiryData", this.items);
      this.dataLoading = false;
    },
    async FilterInit() {
      this.filterLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "StudentInquiries/FilterInit?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      //  this.items =[];
      let res = await this.get(obj);
      // console.log(res);
      this.filterTags = res.tags;
      this.filterClasses = res.cls;
      this.filterLoading = false;
      this.$bvModal.show("modal-login");
    },
    async loadTags() {
      this.tagData = await this.get({
        url:
          this.$store.state.domain +
          "InquiryTag?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      });
    },
    async loadActivity() {
      this.activityData = await this.get({
        url:
          this.$store.state.domain +
          "InquiryActivity?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      });
    },
    async loadCategory() {
      this.categoryData = await this.get({
        url:
          this.$store.state.domain +
          "InquiryCategory?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      });
    },
    async loadPriority() {
      this.priorityData = await this.get({
        url:
          this.$store.state.domain +
          "InquiryPriority?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      });
    },
    async loadRefs() {
      this.referenceData = await this.get({
        url:
          this.$store.state.domain +
          "InquiryReferrence?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      });
    },
    async loadStatus() {
      this.statusData = await this.get({
        url:
          this.$store.state.domain +
          "InquiryStatus?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
